import React, { useState } from "react";
import { useEffect, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "./config";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DataTable from 'react-data-table-component';
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';

const Subscription = () => {
    const [tableData, setTableData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [state, setState] = useState({ invoice: "" })
    const [ids, setIds] = useState("");
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [stateOptions, setStateOptions] = useState([]);
    const [options, setOptions] = useState([])
    const [company, setCompany] = useState("")
    const [selectedOption, setSelectedOption] = useState(null);
    const [editShow, setEditShow] = useState(false)
    const [addShow, setAddShow] = useState(false)


    // Wrap the definition of 'abc' in useCallback
    const history = useHistory();
    const abc = () => {
        if (typeof localStorage !== 'undefined') {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: {} };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData }

            axios
                .post(BASE_URL + "subscriptions/list.php", requestData)
                .then(function (data) {
                    if (data.data.success == true) {
                        setTableData(data.data.data.subscriptions);
                        console.log(data.data.data.subscriptions)

                        setIsLoading(false)
                    }
                })

                .catch(error => console.error(error))
        }
    }

    const LIST = () => {
        if (typeof localStorage !== 'undefined') {
            // Access localStorage here
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: {} };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData }
            axios
                .post(BASE_URL + "company/list.php", requestData)
                .then(function (data) {
                    if (data.data.success == true) {

                        setOptions(data.data.data.companies)
                    }
                })

                .catch(error => console.error(error))
        }
    }


    useEffect(() => {
        const sess = localStorage.getItem('token');
        if (!sess) {
            history.push('/');
        } abc();
        LIST();

        const optionsData = [
            { value: "All", label: "All Sates" },
            { value: "1", label: "Andhra Pradesh" },
            { value: "2", label: "Arunachal Pradesh" },
            { value: "3", label: "Assam" },
            { value: "4", label: "Bihar" },
            { value: "5", label: "Chhattisgarh" },
            { value: "6", label: "Goa" },
            { value: "7", label: "Gujarat" },
            { value: "8", label: "Haryana" },
            { value: "9", label: "Himachal Pradesh" },
            { value: "10", label: "Jharkhand" },
            { value: "11", label: "Karnataka" },
            { value: "12", label: "Kerala" },
            { value: "13", label: "Madhya Pradesh" },
            { value: "14", label: "Maharashtra" },
            { value: "15", label: "Manipur" },
            { value: "16", label: "Meghalaya" },
            { value: "17", label: "Mizoram" },
            { value: "18", label: "Nagaland" },
            { value: "19", label: "Odisha" },
            { value: "20", label: "Punjab" },
            { value: "21", label: "Rajasthan" },
            { value: "22", label: "Sikkim" },
            { value: "23", label: "Tamil Nadu" },
            { value: "24", label: "Telangana" },
            { value: "25", label: "Tripura" },
            { value: "26", label: "Uttarakhand" },
            { value: "27", label: "Uttar Pradesh" },
            { value: "28", label: "West Bengal" },
            { value: "29", label: "Andaman and Nicobar Islands" },
            { value: "30", label: "Chandigarh" },
            { value: "31", label: "Dadra and Nagar Haveli" },
            { value: "32", label: "Daman and Diu" },
            { value: "33", label: "Delhi" },
            { value: "34", label: "Lakshadweep" },
            { value: "35", label: "Puducherry" },
            { value: "36", label: "Jammu and Kashmir" },
            { value: "37", label: "Ladakh" },

        ];

        setStateOptions(optionsData);


    }, []); // Use 'abc' as the dependency for useEffect

    const handleEdit = (id) => {
        setEditShow(true)
        setIds(id.id)

    }

    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [cbox, setCbox] = useState([]);
    const [values, setValues] = useState('')
    const [limits, setLimits] = useState("")
    const [selectedValue, setSelectedValue] = useState(null);

    const handleRadioChange = (event) => {
        setSelectedValue(event.target.value);
        console.log(event.target.value)
        setValues(event.target.value)
        setSelectedCheckboxes("")
        setSelectAll("")
        setCbox([])
        if (event.target.value === "1") {
            setLimits(1)
        } else if (event.target.value === "2") {
            setLimits(2)
        } else if (event.target.value === "3") {
            setLimits(8)
        } else {
            setLimits(37)
            setSelectedCheckboxes(['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37']);
            setCbox([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37]);
        }

    };




    const handleCheckboxChange = (event) => {
        const checkboxValue = event.target.value;
        const isChecked = event.target.checked;
        console.log(checkboxValue + "  " + isChecked)
        console.log(selectedCheckboxes.length + " ==  " + limits)

        if (checkboxValue == "All") {
            setSelectedCheckboxes(isChecked ? stateOptions.map(option => option.value) : []);
            setSelectAll(isChecked);
        } else if (selectedCheckboxes.length === limits && !isChecked) {
            // Allow deselecting any of the already selected checkboxes
            setSelectedCheckboxes(selectedCheckboxes.filter((value) => value !== checkboxValue));
            setCbox(cbox.filter((value) => value !== parseInt(checkboxValue, 10)));
        } else if (selectedCheckboxes.length < limits && isChecked) {
            // Allow selecting up to four checkboxes
            setSelectedCheckboxes([...selectedCheckboxes, checkboxValue]);
            setCbox([...cbox, parseInt(checkboxValue, 10)]);

        }

    };



    const Update = (e) => {
        console.log(ids)
        e.preventDefault();
        if (typeof localStorage !== 'undefined') {
            const token = localStorage.getItem('token');
            const dObject = { authorization: token, input: { "invoice": state.invoice, "id": ids } };
            const encodedData = btoa(JSON.stringify(dObject));
            const requestData = { "data": encodedData }
            axios
                .post(BASE_URL + "subscriptions/edit_inv.php", requestData)
                .then(function (data) {

                    console.log(data)
                    if (data.data.success == true) {
                        toast.success("Invoice edited successfully")
                        setState({ invoice: "" })
                        setEditShow(false)

                        abc();
                    } else {
                        toast.error("Invoice Not Edited")
                        setEditShow(false)

                    }
                });
        }
    };

    const [phone, setPhone] = useState("")

    const handle = (e) => {
        e.preventDefault();
        if (typeof localStorage !== 'undefined') {

            // console.log(values + "--" + cbox + "--" + phone + "--" + ids)
            if (values === null || values === "") {
                toast.error("Please select details")
            } else if (cbox === null || cbox === "") {
                toast.error("Please select details")
            } else if (phone === null || phone === "") {
                toast.error("Please select details")
            } else if (ids === null || ids === "") {
                toast.error("Please select details")
            } else {

                const tokens = localStorage.getItem('token');
                const dObject = { authorization: tokens, input: { "packId": values, "states": cbox, "phone": phone, "id": ids } };
                const encodedData = btoa(JSON.stringify(dObject));
                const requestData = { "data": encodedData }
                axios
                    .post(BASE_URL + "subscriptions/create.php", requestData)
                    .then(function (data) {
                        if (data.data.success == true) {
                            // setShow(false)
                            setSelectedCheckboxes("")
                            setSelectAll("")
                            setCbox("")
                            setLimits("")
                            setIds("")
                            setPhone("")
                            setValues("")
                            toast.success('Subscription Created Successfully')
                            abc();
                            setAddShow(false)

                        } else {
                            toast.error('Subscription Not Created')
                            setAddShow(false)

                        }
                    });

            }
        }
    }

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
      }
    const columns = [
        { name: 'Id', selector: (row) => row.id, width:'80px', sortable: true },
        { name: 'Name', selector: (row) => row.name,width:'150px',  sortable: true },
        { name: 'Order ID', selector: (row) => row.order_id,width:'100px',  sortable: true },
        { name: 'Purchase Date', selector: (row) => formatDate(row.purchasedOn),width:'170px',  sortable: true },
        { name: 'Amount', selector: (row) => "₹ "+row.amount,width:'130px',  sortable: true },
        { name: 'City', selector: (row) => row.city,width:'130px',  sortable: true },
        { name: 'Phone No.', selector: (row) => row.phone,width:'130px',  sortable: true },
        { name: 'State', selector: (row) => row.st.join(', '), width:'330px', sortable: true },
        { name: 'Invoice No.', selector: (row) => row.invoice, width:'130px', sortable: true },
        { name: 'View',width:'90px',  cell: (row) => <img src="assets/images/edit.png" style={{ height: "24px", width: "24px" }} alt="Bin" onClick={() => handleEdit(row)} />, sortable: true },
    ];

    const handlePageChange = (page) => {
        setLoading(true);
        setCurrentPage(page);
    };

    const handleCloseClick = (e) => {
        e.preventDefault();  // Prevent the default form submission behavior
        setState({ status: "" })
        setEditShow(false)
        setAddShow(false)

    };

    const filteredData = searchText
        ? tableData.filter((item) =>
            item.aname.toString().includes(searchText.toString()) ||
            item.city.toLowerCase().includes(searchText.toLowerCase()) ||
            item.phone.toString().includes(searchText.toString())
        )
        : tableData;
    const paginatedData = filteredData.slice((currentPage - 1) * perPage, currentPage * perPage);

    const handleCateChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        setCompany(selectedOption.id)
        console.log(selectedOption.phone)
        setIds(selectedOption.userId)
        setPhone(selectedOption.phone)

        // Add any other logic you want to perform on option change
    };

    const handlePerPageChange = (newPerPage, page) => {
        setPerPage(newPerPage);
        setCurrentPage(page);
    };
    return (
        <div>
            <div class="main-wrapper" >

                <div class="page-wrapper" >

                    <div class="page-content" >
                        <nav class="page-breadcrumb row">
                            <div class="col-md-10">
                                <ol class="breadcrumb">
                                    <li class="breadcrumb-item">Subscriptions</li>
                                    {/* <li class="breadcrumb-item active" aria-current="page">Basic Tables</li> */}
                                </ol>
                            </div>
                            <div class="col-md-2">
                                <button type='button' class="btn btn-primary" style={{ float: "right" }} onClick={() => setAddShow(true)}>Add Subscription</button>
                            </div>
                        </nav>
                        <div class="card">
                            <div class="card-body">

                                <ToastContainer />

                                <div class="" >
                                    {/* <DataTable
                                title="Subscription Details"
                                columns={columns}
                                data={tableData}
                                pagination
                                paginationServer
                                paginationTotalRows={1}
                                paginationPerPage={12}
                                onChangePage={handlePageChange}
                                progressPending={loading}
                                selectableRows nable row selection if needed
                                selectableRowsHighlight // Highlight selected rows if needed
                                paginationComponentOptions={{
                                    rowsPerPageText: 'Rows per page:',
                                    rangeSeparatorText: 'of',
                                }}
                            /> */}

                                    < DataTable
                                        columns={columns}
                                        data={paginatedData}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={tableData.length}
                                        paginationPerPage={perPage}
                                        onChangePage={handlePageChange}
                                        //progressPending={loading}
                                        onChangeRowsPerPage={handlePerPageChange}
                                        subHeader
                                        subHeaderComponent={
                                            <div class="col-md-2">
                                                <input
                                                    type="text"
                                                    placeholder="Search"
                                                    class="form-control"
                                                    value={searchText}
                                                    onChange={(e) => setSearchText(e.target.value)}
                                                />
                                            </div>
                                        } />


                                    <div
                                        className={`modal fade  ${editShow ? 'show' : ''}`}
                                        tabIndex="-1"
                                            style={{ display: editShow ? 'block' : 'none', backgroundColor: "#21211f4a" }}
                                        aria-labelledby="exampleModalLabel"
                                        role={editShow ? "dialog" : ""}
                                        aria-hidden={!editShow}
                                        data-bs-backdrop="static"
                                        aria-modal={editShow ? true : false}
                                    >
                                        <div class="modal-dialog">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Invoice Number</h5>
                                                    {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button> */}
                                                </div>
                                                <div class="modal-body">
                                                    <div class="mb-3">
                                                        <input type="text" value={state.invoice} class="form-control" placeholder="Invoice Number" onChange={e => setState({ "invoice": e.target.value })} />

                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button type="button" class="btn btn-danger" onClick={(e) => Update(e)}>Submit</button>
                                                    <button type="button" class="btn btn-primary" data-bs-dismiss="modal" onClick={(e) => handleCloseClick(e)}>Close</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={`modal fade bd-example-modal-xl ${addShow ? 'show' : ''}`}
                                        tabIndex="-1"
                                        style={{ display: addShow ? 'block' : 'none', backgroundColor: "#21211f4a" }}
                                        aria-labelledby="myExtraLargeModalLabel"
                                        role={addShow ? "dialog" : ""}
                                        aria-hidden={!addShow}
                                        data-bs-backdrop="static"
                                        aria-modal={addShow ? true : false}
                                    >
                                        {/* <div class="modal fade bd-example-modal-xl" tabindex="-1" aria-labelledby="myExtraLargeModalLabel" aria-hidden="true" data-bs-backdrop="static"> */}
                                        <div class="modal-dialog modal-xl" >
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" style={{ textAlign: "center" }} id="exampleModalLabel">Companies</h5>
                                                    {/* <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="btn-close"></button> */}
                                                </div>
                                                <div class="modal-body">

                                                    <form id="signupForm " className='row' onSubmit={handle}>

                                                        <div class="mb-3">
                                                            <p>Select Company</p>
                                                            <Select
                                                                value={selectedOption}
                                                                onChange={handleCateChange}
                                                                options={options}
                                                                getOptionLabel={(option) => `${option.name} (${option.phone}) ${option.personName || ''}`}
                                                                placeholder="Select Company"
                                                                isSearchable
                                                            />
                                                        </div>
                                                        <div class="mb-3">
                                                            <div className="form-check form-check">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="sidebarThemeSettings"
                                                                    id="sidebarDark1"
                                                                    value="1"
                                                                    checked={selectedValue === '1'}
                                                                    onChange={handleRadioChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="sidebarDark1">
                                                                    1 State for 1 year only on Rs.3999
                                                                </label>
                                                            </div>

                                                            <div className="form-check form-check">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="sidebarThemeSettings"
                                                                    id="sidebarDark2"
                                                                    value="2"
                                                                    checked={selectedValue === '2'}
                                                                    onChange={handleRadioChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="sidebarDark2">
                                                                    2 State for 1 year only on Rs.6499
                                                                </label>
                                                            </div>

                                                            <div className="form-check form-check-inl">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="sidebarThemeSettings"
                                                                    id="sidebarDark3"
                                                                    value="3"
                                                                    checked={selectedValue === '3'}
                                                                    onChange={handleRadioChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="sidebarDark3">
                                                                    8 State for 1 year only on Rs.14999
                                                                </label>
                                                            </div>

                                                            <div className="form-check form-check">
                                                                <input
                                                                    type="radio"
                                                                    className="form-check-input"
                                                                    name="sidebarThemeSettings"
                                                                    id="sidebarDark4"
                                                                    value="4"
                                                                    checked={selectedValue === '4'}
                                                                    onChange={handleRadioChange}
                                                                />
                                                                <label className="form-check-label" htmlFor="sidebarDark4">
                                                                    All State for 1 year only on Rs.29999
                                                                </label>
                                                            </div>

                                                        </div>
                                                        <div class="mb-3">
                                                            <label class="form-label">Select States</label>
                                                            <div className="row" style={{ margin: "5px" }}>
                                                                {stateOptions.map((option) => (
                                                                    <div className="col-auto form-check mb-2 mr-3" key={option.value}>
                                                                        <input
                                                                            type="checkbox"
                                                                            className="form-check-input"
                                                                            id={`check${option.value}`}
                                                                            onChange={handleCheckboxChange}
                                                                            name={option.label}
                                                                            checked={selectedCheckboxes.includes(option.value.toString())}
                                                                            value={option.value}
                                                                            disabled={selectedCheckboxes.length >= limits && !selectedCheckboxes.includes(option.value.toString())}
                                                                        />
                                                                        <label className="form-check-label" htmlFor={`check${option.value}`}>
                                                                            {option.label}
                                                                        </label>
                                                                    </div>
                                                                ))}

                                                            </div>

                                                        </div>

                                                        <div class="modal-footer">

                                                            <div class="mb-3">
                                                                <input class="btn btn-primary" style={{ alignContent: "center" }} type="submit" value="Submit" />
                                                            </div>
                                                            <div class="mb-3">
                                                                <button class="btn btn-danger" data-bs-dismiss="modal" aria-label="btn-close" onClick={(e) => handleCloseClick(e)} type="btn" >Close</button>
                                                            </div>
                                                        </div>

                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Subscription;